import React from 'react'
import PropTypes from 'prop-types'

import { InputText, Button } from 'components/ui'
import { userFieldNames as fieldNames } from 'components/user/constants'

UserSubscribe.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

export default function UserSubscribe({
  values,
  errors,
  touched,
  handleChange,
  handleSubmit,
  serverError,
}) {
  return (
    <div className='form-layout'>
      <form onSubmit={handleSubmit}>
        <div className='form-field'>
          <InputText
            placeholder="E-mail"
            name={fieldNames.EMAIL}
            value={values[fieldNames.EMAIL]}
            onChange={handleChange}
            type='email'
            error={touched[fieldNames.EMAIL] && errors[fieldNames.EMAIL]}
          />
        </div>
        <div className='form-field'>
          {
            serverError && 
              <React.Fragment>
                <p className="caption red">{serverError}</p>
                <p><br /></p>
              </React.Fragment>
          }
          <Button text="Подписаться" />
        </div>
      </form>
    </div>
  )
}