import React from 'react'
import cssItem from './item.module.scss'

export default function PapersArchiveItem({
  item,
}) {
  return (
    <a
      href={item.paper}
      className={cssItem.papersArchiveItem}
      target='_blank'
      rel='noreferrer'
    >
      <div
        className={cssItem.image}
        style={{backgroundImage: `url(${item.preview})`}}
      />
      <h5 className={cssItem.title}>
        { item.name }
      </h5>
      <p className={cssItem.date}>
        { item.date }
      </p>
    </a>
  )
}