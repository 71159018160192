import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { values as getValues } from 'ramda'

import { checkServerError, checkServerValidationErrors } from 'utils'
import { subscribe, openSubscribeSuccessPopup } from '../actions'
import { fieldNames } from '../constants'
import SubscribeFormView from './SubscribeFormView'

const mapDispatchToProps = dispatch => ({
  subscribe: bindActionCreators(subscribe, dispatch),
  openSubscribeSuccessPopup: bindActionCreators(openSubscribeSuccessPopup, dispatch),
})

export default connect(null, mapDispatchToProps)(UserSubscribeContainer)

UserSubscribeContainer.propTypes = {
  subscribe: PropTypes.func.isRequired,
  onSuccessSubscribe: PropTypes.func,
  openSubscribeSuccessPopup: PropTypes.func,
}

function UserSubscribeContainer({
  subscribe,
  onSuccess,
  closePopup,
  openSubscribeSuccessPopup,
}) {
  const [serverError, setServerError] = useState('')

  const formik = useFormik({
    initialValues: {
      [fieldNames.EMAIL]: ''
    },
    validationSchema: Yup.object().shape({
      [fieldNames.EMAIL]: Yup.string().email('Некорректный email').required('Обязательное поле'),
    }),
    onSubmit: (values, { setFieldError }) => {
      setServerError('')
      
      subscribe(values[fieldNames.EMAIL])
        .then(response => {
          if (onSuccess) {
            onSuccess()
          } else {
            openSubscribeSuccessPopup()
          }
        })
        .catch(err => {
          checkServerValidationErrors({
            error: err,
            fieldNames: getValues(fieldNames),
            onMatch: (name, validationError) => {
              setFieldError(name, validationError)
            }
          })

          checkServerError({
            error: err,
            onMatch: (errorMessage) => {
              setServerError(errorMessage)
            }
          })
        })
    }
  })

  return (
    <SubscribeFormView
      values={formik.values}
      errors={formik.errors}
      touched={formik.touched}
      handleChange={formik.handleChange}
      handleSubmit={formik.handleSubmit}
      serverError={serverError}
    />
  )
} 