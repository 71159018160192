import React from 'react'
import Item from './Item'
import cssGrid from './grid.module.scss'

export default function PapersArchiveGrid({
  items,
}) {
  return (
    <div className={cssGrid.papersArchiveGrid}>
      {
        items.map((item, index) => (
          <div
            className={cssGrid.item}
            key={index}
          >
            <Item item={item} />
          </div>
        ))
      }
    </div>
  )
}