import React from 'react'
import axios from 'axios'
import { navigate } from 'gatsby'

import { openPopup, closePopup, popupTypes } from 'components/popups/actions'
import { API_BASE_URL } from 'src/config'
import SubscribeForm from './components/SubscribeFormContainer'


export function openSubscribePopup() {
  return dispatch => {
    dispatch(openPopup(popupTypes.POPUP_LAYOUT, {
      title: 'Подписаться на рассылку',
      children: <React.Fragment>
        <p>
          Каждую неделю мы будем присылать вам свежий выпуск нашей газеты.
        </p>
        <SubscribeForm
          onSuccess={() => {
            dispatch(closePopup())
            dispatch(openSubscribeSuccessPopup())
          }}
        />
      </React.Fragment>
    }))
  }
}

export function openSubscribeSuccessPopup() {
  return dispatch => {
    dispatch(openPopup(popupTypes.POPUP_ALERT, {
      title: 'Подписка оформлена!',
      text: 'Вы успешно оформили еженедельную подписку на нашу газету',
      buttonText: 'На главную',
      onClickButton: () => {
        navigate('/')
        dispatch(closePopup())
      }
    }))
  }
}

export function subscribe(email) {
  return async dispatch => {
    try {
      await axios({
        method: 'post',
        url: `${API_BASE_URL}/subscribe/`,
        data: {
          email: email
        }
      });

      return Promise.resolve()
    } catch(e) {
      // console.error(e.response)
      
      return Promise.reject(e)
    }
  }
}