import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import {
  BrowserView,
  MobileView,
} from "react-device-detect";

import css from './block.module.scss'
import { Button } from 'components/ui'
import Grid from './Grid'

export default function PapersArchiveBlockView({
  onClickOpenSubscribePopup,
  fetchPapers,
}) {
  const [papers, setPapers] = useState([])
  const query = '/newspaper'

  useEffect(() => {
    fetchPapers(query)
      .then(result => {
        setPapers(result.results)
      })
  }, [query, /**/fetchPapers])

  return (
    <div className={css.papersArchiveBlock}>
      <div className='container'>
        <h3 className={css.title}>
          Газета “Шабат Шалом” каждую неделю
        </h3>
        <BrowserView>
          <Grid items={papers.filter((item, index) => index < 4)} />
        </BrowserView>
        <MobileView>
          <Grid items={papers.filter((item, index) => index < 1)} />
        </MobileView>
        <div className={css.buttons}>
          <Button
            text='Подписаться на рассылку'
            onClick={onClickOpenSubscribePopup}
          />
          <br />
          <Link to='/archive'>
            Перейти в архив
          </Link>
        </div>
      </div>
    </div>
  )
}