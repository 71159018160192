import React from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { fetchPapers } from 'components/papers/actions'
import { openSubscribePopup } from 'components/subscribe/actions'
import View from './BlockView'

const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => ({
  openSubscribePopup: bindActionCreators(openSubscribePopup, dispatch),
  fetchPapers: bindActionCreators(fetchPapers, dispatch),
})

function PapersArchiveBlockContainer({
  openSubscribePopup,
  fetchPapers,
}) {
  return (
    <View
      onClickOpenSubscribePopup={() => openSubscribePopup()}
      fetchPapers={fetchPapers}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PapersArchiveBlockContainer)